import request from '../request'
import download from '../download'

export function fetchList(params) {
    return request({
        url: '/market-service/bid/user/list',
        params
    })
}

export function downloadFile(data) {
    return download({
        url: '/market-service/bid/user/download',
        data
    })
}

export function audit(data) {
    return request({
        url: '/market-service/bid/user/audit',
        method: "post",
        data
    })
}

export function fetchCompanyList(params) {
    return request({
        url: `/market-service/bid/user/list/supplier`,
        params
    })
}