

<template>
  <div>
    <Pane />

    <a-card class="container">
      <a-row>
        <a-col :span="24">
          <a-form-model
            :colon="false"
            :model="form"
            layout="inline"
            @keyup.enter.native="query"
          >
            <a-form-model-item>
              <a-input
                v-model="form.company"
                placeholder="公司名称"
                style="width: 150px"
              ></a-input>
            </a-form-model-item>
            <a-form-model-item>
              <a-input
                v-model="form.contact"
                placeholder="联系人"
                style="width: 150px"
              ></a-input>
            </a-form-model-item>

            <a-form-model-item>
              <a-select
                v-model="form.status"
                placeholder="审核状态"
                style="width: 150px"
              >
                <a-select-option
                  v-for="item in statusList"
                  :key="item.value"
                  :value="item.value"
                  >{{ item.name }}</a-select-option
                >
              </a-select>
            </a-form-model-item>

            <a-form-model-item>
              <a-space>
                <a-button @click="query" type="primary">查询</a-button>
                <a-button @click="reset">重置</a-button>
              </a-space>
            </a-form-model-item>
          </a-form-model>
        </a-col>
      </a-row>

      <Padding />

      <a-table
        bordered
        :data-source="list"
        :loading="loading"
        @change="onChange"
        :pagination="{
          total,
          current,
          pageSize,
          showTotal: (total) => `共 ${total} 条记录`,
        }"
      >
        <a-table-column title="公司名称" data-index="company"> </a-table-column>
        <a-table-column title="营业执照号码" data-index="licenseCode">
        </a-table-column>
        <a-table-column title="联系人" data-index="contact"> </a-table-column>
        <a-table-column title="联系方式" data-index="mobile"> </a-table-column>

        <a-table-column title="注册时间" data-index="createAt">
        </a-table-column>
        <a-table-column title="审核状态" align="center">
          <template slot-scope="text">
            <div>
              <DataDictFinder
                dictType="bid_user_status"
                :dictValue="text.status"
              />
            </div>
          </template>
        </a-table-column>

        <a-table-column title="操作" align="right" width="140px">
          <template slot-scope="text">
            <a-space>
              <a href="#" v-if="text.status === 'reviewing'" @click="edit(text)"
                >在线审核</a
              >
              <a v-else-if="text.status === 'approved'" @click="view(text)">查看</a>
              <a href="#" @click.prevent="downloadText(text)">下载</a>
            </a-space>
          </template>
        </a-table-column>
      </a-table>
    </a-card>
  </div>
</template>


<script>
import { fetchList, downloadFile } from "@/api/bidding/company";
import { mapGetters } from "vuex";
import { saveAs } from "file-saver";
export default {
  data() {
    return {
      form: {},

      loading: false,
      current: 1,
      pageSize: 10,
      list: [],
      total: 0,
    };
  },

  computed: {
    ...mapGetters("setting", ["findDataDict"]),

    statusList() {
      return this.findDataDict("bid_user_status");
    },
  },

  mounted() {
    this.getList();
  },

  methods: {
    getList() {
      const { current, pageSize, form } = this;
      this.loading = true;
      fetchList({
        pageNum: current,
        pageSize: pageSize,
        ...form,
      })
        .then((res) => {
          if (Array.isArray(res.list)) {
            this.list = res.list;
            this.total = res.totalSize;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },

    onChange(pagination) {
      this.current = pagination.current;
      this.pageSize = pagination.pageSize;
      this.getList();
    },

    query() {
      this.current = 1;
      this.getList();
    },
    reset() {
      this.form = {};
      this.current = 1;
      this.getList();
    },

    edit(text) {
      window.localStorage.setItem(
        "bidding-audit-company",
        JSON.stringify(text)
      );
      this.$router.push(this.$route.path + `/detail?id=${text.id}`);
    },
    view(text){
      this.$router.push(this.$route.path + `/view?id=${text.id}`);
    },
    downloadText(text) {
      downloadFile({
        id: text.id,
      }).then((blob) => {
        saveAs(blob, text.company + ".docx");
      });
    },
  },
};
</script>